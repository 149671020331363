import React from 'react';
import { useJiraServiceManagementProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { useProjectContext } from '../project-context/index.tsx';

export const BOARD_VIEW_TYPE = 'board';
export const CALENDAR_VIEW_TYPE = 'calendar';

export const useViewsRedirect = ({
	viewType,
}: {
	viewType: typeof BOARD_VIEW_TYPE | typeof CALENDAR_VIEW_TYPE;
}) => {
	const { data: navData } = useJiraServiceManagementProjectNavigation();
	const projectContext = useProjectContext();
	const [paramBoardId] = usePathParam('boardId');
	const boardId = Number(paramBoardId);
	const { name } = useCurrentRoute();

	const isInJSMViews = name === 'servicedesk-boards' || name === 'servicedesk-calendar';
	const isAccessingTheRightBoardId = boardId === navData?.boardInfo?.board?.id;
	const isBoardEnabled =
		isAccessingTheRightBoardId && viewType === BOARD_VIEW_TYPE && !!navData.boardInfo?.enabled;
	const isCalendarEnabled =
		isAccessingTheRightBoardId &&
		viewType === CALENDAR_VIEW_TYPE &&
		!!navData.calendarInfo?.enabled;

	if (fg('jsm_views_inside_queues_-_main_flag')) {
		if (isInJSMViews) {
			if (projectContext == null || navData == null || isBoardEnabled || isCalendarEnabled) {
				return null;
			}

			const projectKey = projectContext.project.key;
			return <ScreenRedirect to={`/jira/servicedesk/projects/${projectKey}`} />;
		}

		return null;
	}

	if (
		projectContext == null ||
		navData == null ||
		(viewType === BOARD_VIEW_TYPE && !!navData.boardInfo?.enabled) ||
		(viewType === CALENDAR_VIEW_TYPE && !!navData.calendarInfo?.enabled)
	) {
		return null;
	}

	const projectKey = projectContext.project.key;
	return <ScreenRedirect to={`/jira/servicedesk/projects/${projectKey}`} />;
};
